import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>
Unearthed Arcana
    </h2>As important as your character's race is, it's
(usually) a&nbsp;one-time choice made at the beginning
of your character's career. Your selection of class
levels, on the other hand, is a recurring decision that
is shaped both by personal preference and the realities
of the campaign world.
    <p>
The elevel character classes cover many of the basic
fantasy archetypes, from rhe ragin warrior to the
crafty wizard. But the number of character roles
imaginable is essentially infinite, and even when
factoring in all the prestige class choices
available, you may not be able to find the
combination of class features that perfectly captures
your mental image of your character.
    </p>
    <p>
Presented here, are a wide variety of options that
allow you to tweak the existing character classes
without coompletely scrapping them and starting from
scratch. Whether they involve swapping one class
feature for another or trying a new twist on an old
favorite, these variants allow the players and DM to
retain the familiarity of existing classes while
exploring worlds of difference.
    </p>
    <p>
With your DM's permission, you can use any one of
these variant classes in place of the standard class
of the same name. Depending on the campaign world,
variant classes may exist side by side with standard
classes, or they may replace standard classes
entirely. For instance, the DM may decide that all
monks must choose a fighting style that reflects
their original training, or that all barbarians must
choose a totem.
    </p>
    <p>
Throughout this section and the "New Classes" section
"chracter class" is used to refer to any class that
mey be taken by a 1st level starting character.
    </p>
    <h3><a id="class-feature-variants"></a>
CLASS FEATURE VARIANTS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>Even if you're satisfied playing one of the
standard character classes, other options allow you to
customize your character. Variant versions of several
of the iconic class features common to the character
classes are presented below. If you prefer the variant
to the standard class feature, ask your game master if
he approves of your swapping out your class feature for
the variant version.
    <p>
As with the variant character classes, these variant
class features can exist side by side with the
standard class features � some rangers might hunt
goblinoids while their comrades favor woodland
missions � or can completely replace the standard
features. The balance between the standard class and
the variant is up to the game master.
    </p>
    <h5>
      <a id="favored-environment"></a> FAVORED ENEMY
VARIANT: FAVORED ENVIRONMENT
    </h5>
    <p>
Instead of selecting a type of creature against which
to apply a bonus on certain skills and damage rolls,
the ranger selects a specific natural environment and
gains bonuses when in that environment.
    </p>
    <p>
At 1st level, a <a style={{
        "color": "#579EB6"
      }} href="ranger.html">ranger</a> may
select a natural environment from among those given
on Table: Ranger Favored Environments. Due to the
ranger's experience in that environment, he gains a
+2 bonus on <a style={{
        "color": "#579EB6"
      }} href="skillsAll.html#hide">Hide</a>,
      <a style={{
        "color": "#579EB6"
      }} href="skillsAll.html#listen">Listen</a>, <a style={{
        "color": "#579EB6"
      }} href="
skillsAll.html#move-silently">Move Silently</a>,
      <a style={{
        "color": "#579EB6"
      }} href="skillsAll.html#spot">Spot</a>, and <a style={{
        "color": "#579EB6"
      }} href="
skillsAll.html#survival">Survival</a> checks when
using these skills in that environment. He also gains
the same bonus on <a style={{
        "color": "#579EB6"
      }} href="
skillsAll.html#knowledge">Knowledge</a> (nature)
checks made in association with that environment (or
on Knowledge (dungeoneering) checks made in
association with underground environments, if the
ranger has selected underground as a favored
environment).
    </p>
    <p>
At 5th level and every five levels thereafter (at
10th, 15th, and 20th level), the ranger may select an
additional favored environment from those given on
the table and gains an identical bonus on the
appropriate skill checks in that environment. In
addition, at each such interval, the bonuses in any
one favored environment (including the one just
selected, if so desired) increase by 2. For example,
a 5th-level ranger has two favored environments. In
one he has a +4 bonus on the appropriate skill
checks, and in the other he has a +2 bonus. At 10th
level, he has three favored environments, and he
gains an additional +2 bonus, which he can allocate
to any of his three favored environments. Thus, his
bonuses could be either +4, +4, and +2 or +6,+2, and
+2.
    </p>
    <p>
If the ranger chooses <a style={{
        "color": "#579EB6"
      }} href="wildernessAndEnvironment.html#desert">desert</a> or
      <a style={{
        "color": "#579EB6"
      }} href="wildernessAndEnvironment.html#forest">forest</a>, he
must also choose a climate type, as indicated on the
table (either "cold" or "temperate or warm" for
desert, or "cold or temperate" or "warm" for forest).
    </p>
    <p>
The game master may rule that a ranger can't select
an environment that he has never visited.
    </p><b>Table: Ranger Favored Environments</b>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "40%"
    }}>
      <tbody>
        <tr className="odd-row">
          <th align="left">
Environment
          </th>
          <th align="left">
Examples
          </th>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#aquatic">Aquatic</a>
          </td>
          <td>
sea, ocean (on or under water)
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#desert">Desert</a>,
cold
          </td>
          <td>
tundra
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#desert">Desert</a>,
temperate or warm
          </td>
          <td>
badlands, sandy desert
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#forest">Forest</a>,
cold or temperate
          </td>
          <td>
forest
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#forest">Forest</a>
warm
          </td>
          <td>
jungle
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#hills">Hills</a>
          </td>
          <td>
rugged terrain up to 2,000 feet elevation
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#marsh">Marsh</a>
          </td>
          <td>
bog, moor, swamp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#mountain">Mountain</a>
          </td>
          <td>
rugged terrain above 2,000 feet elevation
          </td>
        </tr>
        <tr>
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html#plains">Plains</a>
          </td>
          <td>
farmland, grassland, steppe, prairie
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a style={{
              "color": "#579EB6"
            }} href="wildernessAndEnvironment.html">Underground</a>
          </td>
          <td>
dungeons, caverns
          </td>
        </tr>
      </tbody>
    </table>
    <h5>
      <a id="whirling-frenzy"></a> RAGE VARIANT: WHIRLING
FRENZY
    </h5><a href="http://www.wizards.com/dnd/images/UnA_Gallery/79153.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
A <a style={{
      "color": "#579EB6"
    }} href="barbarian.html">barbarian</a> with this
variant form of rage doesn't gain the normal bonuses
when he enters a rage. Instead, when a barbarian with
whirling frenzy enters a rage, he temporarily gains a
+4 bonus to Strength and a +2 dodge bonus to Armor
Class and on Reflex saves. While in a whirling frenzy,
the barbarian may make one extra attack in a round at
his highest base attack bonus, but this attack takes a
-2 penalty, as does each other attack made that round.
This penalty applies for 1 round, so it also affects
attacks of opportunity the barbarian might make before
his next action.
    <p>
Whirling frenzy is otherwise identical to the
standard barbarian rage in all other ways. At 11th
level (when a standard barbarian gains greater rage),
the Strength bonus increases to +6, and the dodge
bonus to Armor Class and on Reflex saves increases to
+3. At 20th level (when a standard barbarian gains
mighty rage), the Strength bonus increases to +8, and
the dodge bonus to Armor Class and on Reflex saves
increases to +4.
    </p>
    <p>
A barbarian using this variant doesn't gain
indomitable will at 14th level. Instead, he gains
evasion, but only while in a whirling frenzy.
    </p>
    <p>
A character can't use whirling frenzy at the same
time that he uses any other form of rage (or similar
ability).
    </p>
    <h5>
      <a id="level-check"></a> TURNING UNDEAD VARIANT:
LEVEL CHECK
    </h5>The standard rules for <a style={{
      "color": "#579EB6"
    }} href="
specialAttacks.html#turn">turning</a> undead allow a
    <a style={{
      "color": "#579EB6"
    }} href="cleric.html">cleric</a> to affect a relatively
narrow band of undead (up to 4 HD greater than his
cleric level). This makes many undead creatures "off
limits" for this iconic power of the cleric. This
variant changes the cleric's turning check into a
battle of personality strength, representing it by a
level check that also takes into account the opponents'
Charisma scores.
    <p>
When turning undead using this variant, the cleric
makes a turning check against the closest creature
first, followed by a separate check against the next
closest, and so on. When two or more creatures are
equally close, the creature with the lowest number of
Hit Dice is affected first (if it's still a tie, the
character chooses which is affected first). Creatures
more than 60 feet away cannot be turned.
    </p>
    <p>
A turning check is a level check to which the
character also adds his Charisma modifier. (Use the
character's effective turning level, which is equal
to class level for a cleric or class level -3 for a
paladin.) The DC is 10 + the creature's Hit Dice +
its turn resistance (if any) + its Charisma modifier.
(To speed play, the game master can add a "Turn DC"
score to the statistics block of each undead
creature.)
    </p>
    <p>
If you use this variant, some creatures' turn
resistance should be increased to keep them from
being too easy to turn. Any creature with turn
resistance of +4 or higher should gain an additional
+2 to its turn resistance. Creatures with turn
resistance of +3 or lower need no change.
    </p>
    <p>
If the check fails, the creature is unaffected. Its
Hit Dice do not count against the total number of
creatures the character can turn (see below).
    </p>
    <p>
If the character succeeds on the check, the affected
creature stands frozen in place for 1 round, just as
if it were <a style={{
        "color": "#579EB6"
      }} href="
abilitiesAndConditions.html#paralyzed">paralyzed</a>
(though this affects even creatures with immunity to
paralysis). On each succeeding turn, the character
may take a standard action to concentrate on the
effect, prolonging the effective paralysis for an
additional round. (This doesn't count as a turn
attempt, and affects all undead creatures that the
character has "paralyzed" in this manner.) The
character may concentrate on this effect for a
maximum of 10 consecutive rounds, after which the
undead creature can act normally. If a creature
affected in this way is attacked or takes damage, the
effect is broken and the creature may act normally
beginning on its next turn. (The character's
proximity to the creature has no effect.) This effect
is the same whether the character channels positive
or negative energy.
    </p>
    <p>
If the character's check beats the DC by 5 or more,
he may instead turn the creature (if he channels
positive energy) or rebuke it (if he channels
negative energy).
    </p>
    <p>
If the creature has Hit Dice equal to one-half the
character's effective cleric level or less, the
turning attempt automatically succeeds, and he does
not have to make a check (the creature's Hit Dice
still count against the maximum). Furthermore, such
undead are automatically destroyed (if the character
channels positive energy) or commanded (if he
channels negative energy).
    </p>
    <p>
The character makes checks against each eligible
creature until he has affected the maximum Hit Dice
worth of creatures, or there are no more eligible
creatures. He can affect a number of Hit Dice of
creatures equal to three � his effective cleric level
on any one turning attempt. For example, a 4th-level
cleric (or 7th-level paladin) can affect up to 12 HD
worth of creatures, which could mean two creatures
with 6 HD each, four creatures with 3 HD each, or any
other combination that adds up to 12 HD.
    </p>
    <p>
An evil cleric can instead channel negative energy to
bolster undead or to dispel a turning effect. In
either case, the cleric makes a normal turning check
(if attempting to dispel a turning effect, add the
turning cleric's Charisma modifier to the DC of the
evil cleric's turning check. if the cleric
successfully rebukes the undead, the undead gain turn
resistance +2 (if he was attempting to bolster them)
or are no longer turned (if he was attempting to
dispel the turning effect).
    </p>
    <p>
For example, a 3rd-level cleric, faces three <a style={{
        "color": "#579EB6"
      }} href="
monstersG.html#ghoul">ghouls</a> and a ghast. The
cleric can turn up to 9 HD of creatures. Two of the
ghouls are 10 feet and 15 feet away, respectively.
The third ghoul and the ghast are both 20 feet away.
His turning attempts affect the closest ghouls first,
then the farthest ghoul, and then the ghast. The
cleric makes the first turning check. A ghoul has 2
HD, but it also has +2 turn resistance, so it is
treated as a 4 HD creature. After adding the ghoul's
+1 Charisma modifier, the turning check DC is 15. The
cleric rolls a 12, which, with his three cleric
levels and +1 Charisma modifier, gives him a result
of 16, so the nearest ghoul is halted in place. (If
the cleric wants to maintain the effect, he'll have
to continue to concentrate on it in later rounds.) He
has successfully turned 4 HD worth of creatures,
leaving him with 5 HD. This is enough to affect
another ghoul, so he makes another turning check
against the next closest ghoul. the cleric rolls a 6
against the second ghoul, for a result of 10, so the
ghoul is unaffected. Against the third ghoul, his
roll is 16 for a result of 20, which beats the DC by
at least 5, so the cleric can turn the ghoul if he
wishes to do so. Since he knows he can't affect the
ghast on this turning attempt�it has 4 HD and 2 turn
resistance, and the cleric has only 1 HD of turning
left � he chooses to paralyze the third ghoul rather
than sending it scurrying back into its burrow where
it can gather more allies. (Even if the cleric had
failed to affect the third ghoul, he would only have
5 HD left � not enough to affect the ghast thanks to
its +2 turn resistance.) On his next turn, the cleric
can choose to continue holding the ghouls in place
or, if his allies have already destroyed the three
ghouls, use another turning attempt against the ghast
(which requires a DC 19 turning check).
    </p>
    <h5>
      <a id="planar-banishment"></a> TURNING UNDEAD
VARIANT: PLANAR BANISHMENT
    </h5>Instead of channeling energy to <a style={{
      "color": "#579EB6"
    }} href="
specialAttacks.html#turn">turn</a> or rebuke undead,
with this variant a <a style={{
      "color": "#579EB6"
    }} href="cleric.html">cleric</a>
can channel energy to banish <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#outsider">outsiders</a>.
    <p>
Make a turning check as normal. The result indicates
the most powerful outsider affected (treat an
outsider as having Hit Dice equal to its Hit Dice +
its Cha modifier, if any). if the roll is high enough
to let the cleric affect at least some outsiders
within 60 feet, roll 2d6 + cleric's class level +
cleric's Cha modifier for turning damage. That's how
many total Hit Dice of outsiders the cleric affects.
    </p>
    <p>
Any outsider affected by the turning attempt is
banished back to its home plane. (If it is already on
its home plane, there is no effect.) Even if it has
the ability to travel between planes, it cannot do so
for 10 rounds (1 minute). If the cleric has twice as
many levels (or more) as the outsider has effective
Hit Dice, he destroys any outsiders that he would
normally banish.
    </p>
    <p>
If the cleric would normally turn or destroy undead,
he has the ability to banish or destroy evil
outsiders (that is, outsiders with the <a style={{
        "color": "#579EB6"
      }} href="
monsterTypes.html#evil">evil subtype</a>).
Characters who would normally rebuke or command
undead instead gain the ability to banish or destroy
good outsiders. As an additional option, the game
master might allow clerics worshiping a lawful deity
to banish or destroy chaotic outsiders, while clerics
worshiping a chaotic deity could banish or destroy
lawful outsiders. This ability would replace the
ability to banish or destroy good or evil outsiders.
    </p>
    <p>
If you use this variant, consider allowing clerics
who can turn or rebuke <a style={{
        "color": "#579EB6"
      }} href="
monsterTypes.html#elemental">elementals</a> (that
is, clerics with access to the Air, Earth, Fire, or
Water domains) to instead banish elementals of the
same element and banish or destroy elementals of the
opposing element.
    </p>
    <p>
This variant is otherwise identical to turning
undead, including limits of daily uses and feats that
enhance a cleric's turning ability.
    </p>
    <h5>
      <a id="aspect-of-nature"></a> WILD SHAPE VARIANT:
ASPECT OF NATURE
    </h5>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79154.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Instead of taking <a style={{
      "color": "#579EB6"
    }} href="
monstersAnimal.html">animal</a> form, a <a style={{
      "color": "#579EB6"
    }} href="
druid.html">druid</a> with this variant form of wild
shape takes on one or more aspects of nature when she
uses her wild shape ability.
    <p>
At 5th level, a druid may take on one aspect from
those described below. At 8th level, the druid can
take on up to two aspects simultaneously. At 11th
level, she can take up on to three aspects
simultaneously, and at 15th level the limit increases
to its maximum of four simultaneous aspects. (Some
aspects can only be combined with certain other
aspects, as indicated in their descriptions.) Each
aspect taken on counts as one daily use of the
druid's wild shape ability. Multiple versions of the
same aspect don't stack. Taking on one or more
aspects is a standard action (which does not provoke
attacks of opportunity), and the effect lasts for 1
minute per druid level.
    </p>
    <p>
Some aspects, as noted in their descriptions, have a
minimum druid level as a prerequisite.
    </p>
    <p>
A druid may take on one aspect per day for every
daily use of wild shape she is entitled to. For
instance, a 5th-level druid could normally use wild
shape once per day, so she could assume an aspect
once per day. A 10th-level druid could take on four
aspects per day and can choose to take on two aspects
simultaneously (which would use up two of the druid's
daily uses).
    </p>
    <p>
Unless otherwise noted in an aspect's description, a
druid who assumes an aspect of nature retains her own
type and subtype(s), keeps her extraordinary,
supernatural, or spell-like abilities, and retains
her ability to communicate and cast spells. She is
considered proficient with any natural attacks
granted by the aspect.
    </p>
    <h6>
      <a id="agility"></a> Agility
    </h6>The druid gains a +8 bonus to Dexterity but takes
a -4 penalty to Strength. Prerequisite: Druid level
8th.
    <h6>
      <a id="aquatic"></a> Aquatic
    </h6>The druid grows gills, enabling her to breathe
underwater (while retaining her ability to breathe
air). Webbing between her fingers and her toes grants
her a swim speed of 40 feet (or 30 feet if wearing
medium or heavy armor or carrying a medium or heavy
load) and a +8 bonus on her <a style={{
      "color": "#579EB6"
    }} href="
skillsAll.html#swim">Swim</a> checks.
    <h6>
      <a id="elemental-air"></a> Elemental Air
    </h6>The druids body becomes gaseous (as the <a style={{
      "color": "#579EB6"
    }} href="
spellsFtoG.html#gaseous-form">gaseous form</a> spell,
except that she can fly at a speed of 100 feet with
perfect maneuverability and doesn't lose her
supernatural abilities while in this form). While in
this form, the druid has immunity to <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#poison">poison</a>, sleep,
    <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#paralyzed">paralysis</a>,
and <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#stunned">stunning</a>, as
well as any other immunities provided by the gaseous
form spell. The druid cannot cast spells while this
aspect is in effect. She can't combine this aspect with
any other aspect except for <a style={{
      "color": "#579EB6"
    }} href="
#agility">agility</a> and <a style={{
      "color": "#579EB6"
    }} href="
#endurance">endurance</a>. Prerequisite: Druid level
16th.
    <h6>
      <a id="elemental-earth"></a> Elemental Earth
    </h6>The druid's body becomes stony and rocklike. While
in this form, the druid has immunity to&nbsp;<a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#poison">poison</a>, sleep,
    <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#paralyzed">paralysis</a>,
and <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#stunned">stunning</a>. She
gains a slam attack that deals bludgeoning damage equal
to a morningstar of the druid's size (1d8 for Medium
druids, 1d6 for Small druids). Her natural armor bonus
becomes +8 (replacing any other natural armor bonus the
druid has, though enhancement bonuses to natural armor
still apply normally). She also gains <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#DR">damage reduction</a> 10/magic.
The druid cannot cast spells while this aspect is in
effect. She can't combine this aspect with any other
aspect except for <a style={{
      "color": "#579EB6"
    }} href="#endurance">endurance</a>
and <a style={{
      "color": "#579EB6"
    }} href="#vigor">vigor</a>. Prerequisite: Druid
level 16th.
    <h6>
      <a id="elemental-fire"></a> Elemental Fire
    </h6>The druid's body bursts into flame. While in this
form, the druid has immunity to fire,&nbsp;<a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#poison">poison</a>, sleep,
    <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#paralyzed">paralysis</a>,
and <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#stunned">stunning</a>. In
addition, any creature struck by the druid in melee
(whether with a weapon, unarmed attack, or natural
weapon) takes an extra 1d6 points of fire damage and
must succeed on a Reflex save or
    <a style={{
      "color": "#579EB6"
    }} href="wildernessAndEnvironment.html#cathing-on-fire">catch
fire</a> for 1d4 rounds. The save DC is 10 + 1/2 druid
level + druid's Con modifier. Creatures hitting the
druid with natural weapons or unarmed attacks while
this aspect is in effect take 1d6 points of fire damage
and also catch fire unless they succeed on the Reflex
save noted above. The druid also gains <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#DR">damage reduction</a> 10/magic.
The druid cannot cast spells while this aspect is in
effect. She can't combine this aspect with any other
aspect except for <a style={{
      "color": "#579EB6"
    }} href="#agility">agility</a> and
    <a style={{
      "color": "#579EB6"
    }} href="#endurance">endurance</a>. Prerequisite: Druid
level 16th.
    <h6>
      <a id="elemental-water"></a> Elemental Water
    </h6>The druid's body becomes semifluid. While in this
form, the druid gains a +10 bonus on <a style={{
      "color": "#579EB6"
    }} href="
skillsAll.html#escape-artist">Escape Artist</a>
checks, <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#resistance-to-energy">resistance to
fire</a> 10, and immunity to&nbsp;<a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#poison">poison</a>, sleep,
    <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#paralyzed">paralysis</a>,
and <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#stunned">stunning</a>. She
gains a swim speed of 90 feet and a +8 bonus on her
    <a style={{
      "color": "#579EB6"
    }} href="skillsAll.html#swim">Swim</a> checks. Her
touch puts out torches, campfires, exposed lanterns,
and other open flames of nonmagical origin if these are
Large or smaller. The druid can dispel magical fire she
touches as if she had cast <a style={{
      "color": "#579EB6"
    }} href="spellsDtoE.html#greater-dispel-magic">greater dispel
magic</a> on it. She also gains <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#DR">damage reduction</a> 10/magic.
The druid cannot cast spells while this aspect is in
effect. She can't combine this aspect with any other
aspect except for <a style={{
      "color": "#579EB6"
    }} href="#endurance">endurance</a>
and <a style={{
      "color": "#579EB6"
    }} href="#vigor">vigor</a>. Prerequisite: Druid
level 16th.
    <h6>
      <a id="endurance"></a> Endurance
    </h6>The druid gains a +4 bonus to Constitution.
Prerequisite: Druid level 8th.
    <h6>
      <a id="flight"></a> Flight
    </h6>The druid grows wings feathery or batlike, at her
option) that enable her to fly at a speed of 40 feet
with average maneuverability (or 30 feet if wearing
medium or heavy armor or carrying a medium or heavy
load).
    <h6>
      <a id="plant"></a> Plant
    </h6>The druid's body becomes plantlike. While in this
form, the druid gains a +10 bonus on <a style={{
      "color": "#579EB6"
    }} href="
skillsAll.html#hide">Hide</a> checks made in areas of
forest, overgrowth, or similar terrain. She gains
immunity to&nbsp;<a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#poison">poison</a>, sleep,
    <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#paralyzed">paralysis</a>,
and <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#stunned">stunning</a>. She
gains a slam attack that deals bludgeoning damage equal
to a light mace of the druid's size (1d6 for Medium
druids, 1d4 for Small druids). Her natural armor bonus
becomes 4 (replacing any other natural armor bonus the
druid has, though enhancement bonuses to natural armor
still apply normally). She can't combine this aspect
with any other aspect except for <a style={{
      "color": "#579EB6"
    }} href="
#vigor">vigor</a>. Prerequisite: Druid level 12th.
    <h6>
      <a id="poison"></a> Poison
    </h6>The druid gains a bite attack that deals
bludgeoning, piercing, and slashing damage equal to a
dagger of the druid's size (1d4 for a Medium druid, or
1d3 for a Small druid). In addition, the bite delivers
a toxic <a style={{
      "color": "#579EB6"
    }} href="
abilitiesAndConditions.html#venom">venom</a>
(Fortitude save DC 10 + 1/2 druid's level + druid's Con
modifier; initial and secondary damage 1d6 Con).
    <h6>
      <a id="scent"></a> Scent
    </h6>The druid gains the <a style={{
      "color": "#579EB6"
    }} href="
monsterTypes.html#scent">scent</a> ability.
    <h6>
      <a id="speed"></a> Speed
    </h6>The druid gains a +30-foot enhancement bonus to
her base land speed.
    <h6>
      <a id="tooth-and-claw"></a> Tooth and Claw
    </h6>The druid gains a primary bite attack (at her full
base attack bonus) and two secondary claw attacks (at
her base attack bonus -5 and adding only half her
Strength bonus on damage rolls). The bite attack deals
bludgeoning, piercing, and slashing damage equal to a
short sword of the druid's size (1d6 for a Medium
druid, or 1d4 for a Small druid), while the claws deal
piercing and slashing damage equal to a dagger of the
druid's size (1d4 for a Medium druid, or 1d3 for a
Small druid).
    <h6>
      <a id="vigor"></a> Vigor
    </h6>The druid gains a +8 bonus to Strength but takes a
-4 penalty to Dexterity. Prerequisite: Druid level 8th.
    <h5>
      <a id="druid-domains"></a> HOUSE RULE: DRUID
DOMAINS
    </h5>With this option, each druid may choose a single
domain from the following list: <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#air">Air</a>, <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#animal">Animal</a>, <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#earth">Earth</a>, <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#fire">Fire</a>, <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#plant">Plant</a>, <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#sun">Sun</a>, and <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html#water">Water</a>. The druid gains a
bonus spell of each spell level, which may be used only
to prepare a domain spell of that level (or of a lower
level, if affected by a metamagic feat). The druid also
gains the domain's granted power, except as noted
below.
    <p>
Druids who select the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#animal">Animal</a> domain or the
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#plant">Plant</a> domain
gain <a style={{
        "color": "#579EB6"
      }} href="featsAll.html#skill-focus">Skill
Focus</a> (Knowledge [nature]), since Knowledge
(nature) is already a class skill for druids.
    </p>
    <p>
A druid who chooses the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#sun">Sun</a> domain gains the
ability to <a style={{
        "color": "#579EB6"
      }} href="specialAttacks.html#turn">turn
undead</a> 1/day as a cleric of her level, and cannot
perform a greater turning.
    </p>
    <h3><a id="spontaneous-divine-casters"></a>
SPONTANEOUS DIVINE CASTERS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>As a twist on the traditional divine
spellcaster, this variant converts the <a style={{
      "color": "#579EB6"
    }} href="
cleric.html">cleric</a> and <a style={{
      "color": "#579EB6"
    }} href="
druid.html">druid</a> into spontaneous spellcasters.
Such characters have a limited number of spells known,
as the sorcerer does, though their selection is not
quite as limited as the sorcerer's list.
    <p>
Like other spellcasters, a character using this
variant system can cast a certain number of spells
per day. His base daily spell allotment is the same
as a normal cleric's number of spells per day (not
including domain spells), plus one spell per day of
each spell level he can cast. For instance, a
1st-level cleric using this system can cast four
0-level spells and two 1st-level spells per day.
    </p>
    <p>
However, the divine caster's selection of spells
known is limited. At 1st level, the character begins
play knowing four 0-level spells and two 1st-level
spells of your choice, plus his two 1st-level
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html">domain spells</a> (if a
cleric) or <a style={{
        "color": "#579EB6"
      }} href="
spellsS.html#summon-natures-ally-i">summon nature's
ally I</a> (if a druid). At each new level in the
character's divine spellcasting class, he gains one
or more new spells, as indicated below:
    </p><b>Table: Spells Known</b>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th rowSpan="2">
Level
          </th>
          <th colSpan="10">
Spells Known
          </th>
        </tr>
        <tr>
          <th>
0
          </th>
          <th>
1st
          </th>
          <th>
2nd
          </th>
          <th>
3rd
          </th>
          <th>
4th
          </th>
          <th>
5th
          </th>
          <th>
6th
          </th>
          <th>
7th
          </th>
          <th>
8th
          </th>
          <th>
9th
          </th>
        </tr>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td>
4
          </td>
          <td>
2
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td>
5
          </td>
          <td>
2
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
3rd
          </td>
          <td>
5
          </td>
          <td>
3
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td>
6
          </td>
          <td>
3
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td>
6
          </td>
          <td>
4
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
6th
          </td>
          <td>
7
          </td>
          <td>
4
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
7th
          </td>
          <td>
7
          </td>
          <td>
5
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
8th
          </td>
          <td>
8
          </td>
          <td>
5
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
9th
          </td>
          <td>
8
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
10th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
11th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
12th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
13th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
14th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
15th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
          <td>
�
          </td>
        </tr>
        <tr>
          <td>
16th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
          <td>
�
          </td>
        </tr>
        <tr className="odd-row">
          <td>
17th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
0
          </td>
        </tr>
        <tr>
          <td>
18th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
          <td>
1
          </td>
        </tr>
        <tr className="odd-row">
          <td>
19th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
2
          </td>
        </tr>
        <tr>
          <td>
20th
          </td>
          <td>
9
          </td>
          <td>
5
          </td>
          <td>
5
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
4
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
          <td>
3
          </td>
        </tr>
      </tbody>
    </table>
    <p>
Characters who use this option lose the ability to
spontaneously cast cure, inflict, or summon nature's
ally spells in place of other spells. However, each
time the character gains a new spell level, he gains
one or more bonus spells known to add to his list. A
cleric may add his two domain spells to his list of
spells known, while a druid may add the appropriate
summon nature's ally spell to her list of spells
known. (An entry of 0 on the table indicates that the
cleric knows only his domain spells of that level,
and the druid knows only the summon nature's ally
spell of that level.)
    </p>
    <p>
Upon reaching 4th level, and at every even-numbered
class level after that, a cleric or druid can choose
to learn a new spell in place of one he already
knows. This functions identically to the sorcerer's
ability to swap out known spells, except that a
cleric may never choose to lose a domain spell and a
druid may never choose to lose a summon nature's ally
spell.
    </p>
    <p>
For example, a cleric has chosen the domains of Good
and Healing, which means that at 1st level, he
automatically knows <a style={{
        "color": "#579EB6"
      }} href="
spellsC.html#cure-light-wounds">cure light
wounds</a> and <a style={{
        "color": "#579EB6"
      }} href="
spellsPtoR.html#protection-from-evil">protection
from evil</a>. In addition, he chooses four spells
from the list of 0-level cleric spells (<a style={{
        "color": "#579EB6"
      }} href="
spellsC.html#cure-minor-wounds">cure minor
wounds</a>, <a style={{
        "color": "#579EB6"
      }} href="spellsDtoE.html#detect-magic">detect magic</a>,
      <a style={{
        "color": "#579EB6"
      }} href="spellsHtoL.html#light">light</a>, and
      <a style={{
        "color": "#579EB6"
      }} href="spellsPtoR.html#read-magic">read magic</a>)
and two spells from the list of 1st-level cleric
spells (<a style={{
        "color": "#579EB6"
      }} href="spellsAtoB.html#bless">bless</a> and
      <a style={{
        "color": "#579EB6"
      }} href="spellsS.html#shield-of-faith">shield of
faith</a>). He now knows four 0-level spells and four
1st-level spells.
    </p>
    <p>
Another example: At 4th level, a druid learns a new
0-level spell and a new 2nd-level spell. She can also
choose to replace one of her 0-level spells known
with a different spell of the same level. She chooses
to replace <a style={{
        "color": "#579EB6"
      }} href="
spellsHtoL.html#know-direction">know direction</a>
with <a style={{
        "color": "#579EB6"
      }} href="spellsDtoE.html#detect-poison">detect
poison</a>.
    </p>
    <h5>
      <a id="spontaneous-casting"></a> BEHIND THE
CURTAIN: SPONTANEOUS CASTING
    </h5>This option trades versatility � one of the divine
spellcaster's strengths � for sheer spellcasting power
(much like the difference between <a style={{
      "color": "#579EB6"
    }} href="
sorcerer.html">sorcerers</a> and <a style={{
      "color": "#579EB6"
    }} href="
wizard.html">wizards</a>). Since the <a style={{
      "color": "#579EB6"
    }} href="
clericSpells.html">cleric</a> and <a style={{
      "color": "#579EB6"
    }} href="
druidSpells.html">druid spell lists</a> depend on
versatility of effect, particularly defensive or
utilitarian spells the spontaneous-casting divine
caster is allowed to know more spells per spell level
than the sorcerer (by adding domain spells or summon
nature's ally spells on the list of spells known). No
longer is the divine caster the character who can come
up with any effect under the sun; instead, he becomes a
much more specialized member of the adventuring group.
    <p>
This variant has the secondary effect of
individualizing the divine casters in your game,
since no two characters choose to learn the same set
of spells. With only a limited number of spells known
from which to choose, characters must make tough
choices each time they gain new spells known. For
instance, is it more important that a 4th-level
cleric learn <a style={{
        "color": "#579EB6"
      }} href="
spellsC.html#cure-moderate-wounds">cure moderate
wounds</a> � particularly if he already knows
      <a style={{
        "color": "#579EB6"
      }} href="spellsC.html#cure-light-wounds">cure light
wounds</a> � or <a style={{
        "color": "#579EB6"
      }} href="
spellsAtoB.html#bears-endurance">bear's
endurance</a>? Should your druid learn <a style={{
        "color": "#579EB6"
      }} href="
spellsPtoR.html#resist-energy">resist energy</a> as
a 2nd level spell, or should she wait until she gains
access to 3rd-level spells and learn <a style={{
        "color": "#579EB6"
      }} href="
spellsPtoR.html#protection-from-energy">protection
from energy</a> instead? The cleric's choice of
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html">domains</a> becomes
crucial, because those areas form the backbone of his
available spells.
    </p>
    <h5>
      <a id="spontaneous-domain-casting"></a> HOUSE RULE:
SPONTANEOUS DOMAIN CASTING
    </h5>Rather than allowing a <a style={{
      "color": "#579EB6"
    }} href="
cleric.html">cleric</a> to prepare one spell each day
from either of his <a style={{
      "color": "#579EB6"
    }} href="
clericDomains.html">domains</a>, allow the cleric to
spontaneously cast any domain spell in place of a
prepared spell of the same level or lower.
    <p>
This functions identically with the cleric's ability
to spontaneously cast cure spells. The cleric "loses"
a prepared spell to cast either one of his domain
spells of the same level or lower.
    </p>
    <p>
This house rule reduces a cleric's overall spell
power by one spell per spell level, and makes his
choice of domains much more significant. A cleric who
selects the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#chaos">Chaos</a> and <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#luck">Luck</a> domains seems a
very different spellcaster from one who selects the
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#good">Good</a> and
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#strength">Strength</a>
domains.
    </p>
    <p>
If you use this variant, the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#healing">Healing</a> domain loses
much of its appeal, since six of the nine spells
associated with that domain are cure spells and thus
available for spontaneous casting by all clerics who
channel positive energy. (To a lesser extent, this
variant diminishes the value of the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#destruction">Destruction</a>
domain, but since only three of that domain's spells
are inflict spells, the effect is much less severe.)
Consider granting clerics who select the Healing
domain the ability to use d12s instead of d8s when
casting cure spells. Thus, such a cleric's <a style={{
        "color": "#579EB6"
      }} href="
spellsC.html">cure light wounds</a> spell would cure
1d12 damage +1/level, and his caster level is treated
as one higher than normal thanks to the granted
power.
    </p>
    <p>
Alternatively, you can simply eliminate the normal
cleric's ability to spontaneously cast cure or
inflict spells. This makes the selection of the
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#healing">Healing</a>
domain (or the <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#destruction">Destruction</a>
domain, for evil clerics) into an extraordinarily
powerful choice, but simultaneously places a dramatic
limitation on the average cleric's ability to heal
damage. If you use this option, you might want to
allow a cleric of any good deity to select the
Healing domain as one of his domains.
    </p>
    <p>
For example, a 1st-level cleric has selected access
to the domains of <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#air">Air</a> and <a style={{
        "color": "#579EB6"
      }} href="
clericDomains.html#animal">Animal</a>. He may cast
one 1st-level spell for being a 1st-level cleric,
plus one 1 st-level bonus spell because of his 15
Wisdom. Instead of preparing one domain spell (either
      <a style={{
        "color": "#579EB6"
      }} href="spellsMtoO.html#obscuring-mist">obscuring
mist</a> or <a style={{
        "color": "#579EB6"
      }} href="spellsC.html#calm-animals">calm
animals</a>) each day, he may spontaneously cast
obscuring mist or calm animals in place of either or
both of his prepared 1 st-level spells. He does not
prepare domain spells. If this cleric had chosen the
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#earth">Earth</a> and
      <a style={{
        "color": "#579EB6"
      }} href="clericDomains.html#plant">Plant</a> domains,
he could spontaneously cast <a style={{
        "color": "#579EB6"
      }} href="
spellsMtoO.html#magic-stone">magic stone</a> or
      <a style={{
        "color": "#579EB6"
      }} href="spellsDtoE.html#entangle">entangle</a> in
place of either or both of his prepared 1st-level
spells.
    </p>
    <p>
If you use the <a style={{
        "color": "#579EB6"
      }} href="#druid-domains">Druid
Domains</a> house rule, you could allow such
characters to use these same spontaneous casting
rules (either in addition to or in exchange for the
existing <a style={{
        "color": "#579EB6"
      }} href="druid.html">druid</a> ability to
spontaneously cast summon nature's ally spells).
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      